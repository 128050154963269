import { UserRoleEnum } from '@/@types/User';
import { useTranslation } from 'react-i18next';

export function useRoleTranslation(role: UserRoleEnum): string {
  const { t } = useTranslation('enums/role');

  const strategyTransLationRole = {
    [UserRoleEnum.ROOT]: t('root'),
    [UserRoleEnum.OWNER]: t('owner'),
    [UserRoleEnum.ADMIN]: t('admin'),
    [UserRoleEnum.MANAGER]: t('manager'),
    [UserRoleEnum.OPERATOR]: t('operator'),
    [UserRoleEnum.SUPPORT]: t('support'),
    [UserRoleEnum.SELLER]: t('seller'),
    [UserRoleEnum.FINANCIAL]: t('financial'),
  };

  return strategyTransLationRole[role] || '';
}
